<template>
  <thead class="border-b border-bb-neutral-50">
    <tr>
      <th
        v-for="(header, index) in headers"
        :key="index"
        :class="index === 0 ? 'sticky left-0 bg-white rounded-tl-md' : ''"
      >
        <div
          class="h-full whitespace-no-wrap w-full flex gap-1 items-center text-bb-text-default p-4 pr-0"
          :class="{ 'justify-end': header.position === 'right' }"
          @mouseenter="hoveredHeader = header.value"
          @mouseleave="hoveredHeader = null"
        >
          <p
            class="text-base"
            :class="sortingOrder.column === header.value ? 'font-bold' : 'font-medium'"
          >
            {{ header.label }}
          </p>
          <div class="h-5 w-5">
            <div v-if="header.sortable">
              <dropdown-arrow
                v-if="sortingOrder.column === header.value || hoveredHeader === header.value"
                :direction="sortingOrder.order === 'ASCENDING' && sortingOrder.column === header.value ? 'down' : 'up'"
                @click="$emit('sort-table', header)"
              />
            </div>
          </div>
        </div>
      </th>
    </tr>
  </thead>
</template>

<script>
import DropdownArrow from '@/components/icon/brightbid/dropdown_arrow'

export default {
  name: 'TableHeader',
  components: { DropdownArrow },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    sortingOrder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hoveredHeader: null,
    }
  },
}
</script>

<style scoped></style>
