<template>
  <div
    ref="brightbid_table"
    class="rounded-md"
  >
    <div
      class="overflow-x-auto base-scrollbar bg-white"
      :class="{ 'overflow-y-auto h-full': scrollY }"
      :style="`height: ${tableHeight}`"
    >
      <table class="table-class bg-white table-fixed rounded-t-md min-w-full">
        <!--HEADER-->
        <table-header
          class="bg-white z-10"
          :class="{ 'sticky top-0': stickyHeader }"
          :headers="headers"
          :sorting-order="sortingOrder"
          @sort-table="sortTable"
        />

        <!--BODY-->
        <slot
          v-if="!isLoading"
          name="rows"
          :table-data="tableData"
        />
        <!--BODY LOADING STATE-->
        <tbody v-if="isLoading">
          <tr>
            <td :colspan="headers.length">
              <div>
                <slot name="loader">
                  <div
                    class="flex items-center justify-center"
                    :style="`height: calc(${tableHeight} - 80px)`"
                  >
                    <brightbid-loader size="100" />
                  </div>
                </slot>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bg-white rounded-b-md border-t">
      <!--FOOTER-->
      <div class="flex items-center justify-between h-16 px-8">
        <div>
          <!--Placeholder can either be static or dynamic-->
          <search-input
            v-model="rowsPerPage"
            input-name="rows-per-page"
            input-id="rows-per-page"
            :placeholder="rowsPerPage.text"
            :options="rowsPerPageOption"
            dynamic-placeholder
            :required="false"
            @select-item="changeRowsPerPage"
          />
        </div>
        <div>
          <table-pagination
            :limit="rowsPerPage.value"
            :page="currentPage"
            :max="data.length"
            @onChange="changePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/seamless-scroll-polyfill@latest/lib/bundle.min.js"></script>

<script>
import { sort, createNewSortInstance } from 'fast-sort'

import TableHeader from '@/components/table/TableHeader'
import TablePagination from '@/components/table/TablePagination'
import SearchInput from '@/components/input/brightbid/SearchInput'
import BrightbidLoader from '@/components/loader/BrightbidLoader'

const SORTING = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
}

const rowsPerPageOption = [
  { label: '10', value: 10, text: 'View 10 per page' },
  { label: '20', value: 20, text: 'View 20 per page' },
  { label: '50', value: 50, text: 'View 50 per page' },
  { label: '100', value: 100, text: 'View 100 per page' },
]

const defaultRowsPerPage = { label: '10', value: 10, text: 'View 10 per page' }

export default {
  name: 'BbTable',
  components: {
    TableHeader,
    TablePagination,
    SearchInput,
    BrightbidLoader,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    defaultSortingOrder: {
      type: Object,
      default: () => ({ order: 'ASCENDING', column: null }),
    },
    scrollY: {
      type: Boolean,
      default: false,
    },
    stickyHeader: {
      type: Boolean,
      default: false,
    },
    applyInitialSort: {
      //Whether apply the initial sorting which is to sort from the first column or not
      type: Boolean,
      default: true,
    },
    tableHeight: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      rowsPerPage: defaultRowsPerPage,
      currentPage: 1,
      paginationData: {},
      sortingOrder: {},
      rowsPerPageOption,
      sortedTableData: [],
    }
  },
  mounted() {
    this.setSortingOrder()
    this.applySortingOrder(this.sortingOrder)

    // set initial pagination data
    this.setInitialTablePagination()
  },
  computed: {
    tableData() {
      return this.sortedTableData.slice(this.paginationData.from - 1, this.paginationData.to)
    },
  },
  watch: {
    data() {
      if (this.applyInitialSort) {
        this.setSortingOrder()
        this.applySortingOrder(this.sortingOrder)
      } else {
        this.sortedTableData = this.data
      }
    },
  },
  methods: {
    setInitialTablePagination() {
      this.paginationData = {
        current_page: 1,
        from: 1,
        to: this.rowsPerPage.value,
        per_page: this.rowsPerPage.value,
      }
    },
    changePage(paginationData) {
      this.currentPage = paginationData.current_page
      this.paginationData = paginationData
      this.scrollToTop()
    },
    scrollToTop() {
      this.$nextTick(() => {
        seamless.scrollIntoView(this.$refs.brightbid_table, {
          behavior: 'smooth',
        })
      })
    },
    decideTheSortingOrder(data) {
      // when no sorting order is set
      if (this.sortingOrder === null) return SORTING.ASCENDING

      // when sorting another column
      if (data.value !== this.sortingOrder.column) return SORTING.ASCENDING

      // switch between ascending and descending
      if (data.value === this.sortingOrder.column) {
        return this.sortingOrder.order === SORTING.ASCENDING ? SORTING.DESCENDING : SORTING.ASCENDING
      }
    },
    async sortTable(data) {
      this.$nextTick(() => {
        const order = this.decideTheSortingOrder(data)
        const column = data.value
        this.sortingOrder = { order: order, column: column } // will be sent to child, table rows component must handle the sorting
        this.applySortingOrder(this.sortingOrder)
        this.setInitialTablePagination() // reset pagination data
        this.changePage(this.paginationData) // go back to first page
      })
    },
    applySortingOrder(sortingOrder) {
      const naturalSort = createNewSortInstance({
        comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare,
      })
      if (sortingOrder.order === 'ASCENDING') {
        this.sortedTableData = naturalSort(this.data).asc(sortingOrder.column)
      }
      if (sortingOrder.order === 'DESCENDING') {
        this.sortedTableData = naturalSort(this.data).desc(sortingOrder.column)
      }
    },
    setSortingOrder() {
      this.sortingOrder = {
        column: this.defaultSortingOrder.column ?? this.headers[0].value,
        order: this.defaultSortingOrder.order,
      }
    },
    changeRowsPerPage(item) {
      this.rowsPerPage = item
      this.currentPage = 1
      this.paginationData = {
        current_page: 1,
        from: 1,
        to: this.rowsPerPage.value,
        per_page: this.rowsPerPage.value,
      }
      this.scrollToTop()
    },
  },
}
</script>
